import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'

interface Argument {
  data: unknown[][]
  filename: string
  headers?: string[]
  disabled?: boolean
}

const csvDownload = function ({ data, filename, headers }: Argument) {
  const textHeader = headers === undefined ? '' : headers.join(',') + '\n'
  const textBody = data
    .map((row) => row.map((x) => (typeof x === 'string' && x.includes('\n') ? `"${x}"` : x)).join(','))
    .join('\n')
  const text = textHeader + textBody

  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const downloadLink = document.createElement('a')
  downloadLink.download = filename + '.csv'
  downloadLink.href = URL.createObjectURL(new Blob([bom, text], { type: 'text/csv' }))
  downloadLink.dataset.downloadurl = ['text/csv', downloadLink.download, downloadLink.href].join(':')
  downloadLink.click()
}

export const CsvDownloadButton = ({ data, filename, headers, disabled }: Argument) => (
  <Button
    icon={<DownloadOutlined />}
    onClick={() => {
      csvDownload({ data, filename, headers })
    }}
    disabled={disabled ?? false}
  />
)
