import { OrganizationPlanTypeEnum, UserPlanEnum } from '@salescore/admin-client-api'

export const USER_PLAN_OPTIONS = [
  {
    value: UserPlanEnum.Standard,
    label: '有料',
  },
  {
    value: UserPlanEnum.Free,
    label: '無料',
  },
  {
    value: UserPlanEnum.None,
    label: 'なし',
  },
]

export const ORGANIZATION_PLAN_OPTIONS = [
  {
    value: '',
    label: 'なし',
  },
  {
    value: OrganizationPlanTypeEnum.Standard,
    label: '有料',
  },
  {
    value: OrganizationPlanTypeEnum.Free,
    label: '無料',
  },
]
