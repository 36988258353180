import { useMutation } from '@apollo/client'
import {
  type OrganizationFieldsFragment,
  OrganizationPlanTypeEnum,
  UpdateOrganizationPlanDocument,
} from '@salescore/admin-client-api'
import { Card, message, Select } from 'antd'
import type { ReactElement } from 'react'

import { ORGANIZATION_PLAN_OPTIONS } from '../../../common/planOptions'

export const OrganizationPlanCard = ({
  organization,
  onCompleted,
}: {
  organization: OrganizationFieldsFragment
  onCompleted: () => void
}): ReactElement => {
  const dataExtensionLicense = organization.organizationPlans.find((x) => x.license === 'dataExtension')
  const [updateOrganizationPlanMutation, { loading }] = useMutation(UpdateOrganizationPlanDocument)
  return (
    <Card className="m-5 w-[450px]" title="組織ライセンス">
      {`DataExtension :   `}
      <Select
        value={dataExtensionLicense?.plan ?? ''}
        onChange={(value) => {
          void updateOrganizationPlanMutation({
            variables: {
              organizationId: organization.id,
              license: 'dataExtension',
              ...((value === OrganizationPlanTypeEnum.Free || value === OrganizationPlanTypeEnum.Standard) && {
                plan: value,
              }),
            },
            onCompleted() {
              void message.success(`組織ライセンスを更新しました`)
              onCompleted()
            },
            onError() {
              void message.error(`エラーが発生しました`)
            },
          })
        }}
        options={ORGANIZATION_PLAN_OPTIONS}
        loading={loading}
      />
    </Card>
  )
}
