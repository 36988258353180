import { SearchOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Button, Input, Space } from 'antd'
import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useRef } from 'react'

export function getColumnSearchProps<T>(recordToValue: (record: T) => string) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      const reference = useRef(null)

      // 正しい方法ではなさそうだが、これ以外でfocusできなかった
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const current: any = reference.current
        if (isSome(current)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          current.focus()
        }
      }, 200)

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={reference}
            // placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(event) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              setSelectedKeys(event.target.value === undefined ? [] : [event.target.value])
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => {
              confirm({ closeDropdown: true })
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            {/* <Button
            type="primary"
            onClick={(e) => { confirm({ closeDropdown: true }) }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            検索
          </Button> */}
            <Button
              onClick={() => {
                clearFilters?.()
              }}
            >
              リセット
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFilter: (value: any, record: T) =>
      recordToValue(record)
        .toLowerCase()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        .includes(typeof value === 'string' ? value.toLowerCase() : (value as string)),
  }
}
