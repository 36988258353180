import { useMutation } from '@apollo/client'
import {
  type OrganizationFieldsFragment,
  RestoreDeletedOrganizationDocument,
  SourceProviderEnum,
} from '@salescore/admin-client-api'
import { compareFunction } from '@salescore/buff-common'
import { App, Avatar, Button, Popconfirm, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { Array, pipe } from 'effect'
import type { ReactElement } from 'react'

import { getColumnSearchProps as getColumnSearchProperties } from '../../common/antd'
import { OrganizationStatusTag } from '../shared/OrganizationStatusTag'
import { ProviderLogo } from '../shared/ProviderLogo'
import { SubscriptionPlanTag } from '../shared/SubscriptionPlanTag'

const RestoreDeletedOrganizationButton = ({
  organizationId,
  onCompleted,
}: {
  organizationId: string
  onCompleted: () => void
}): ReactElement => {
  const { message } = App.useApp()

  const [restoreDeletedOrganizationMutation, { loading }] = useMutation(RestoreDeletedOrganizationDocument)
  return (
    <Popconfirm
      title="本当に復旧しますか？"
      onConfirm={() => {
        void restoreDeletedOrganizationMutation({
          variables: {
            organizationId,
          },
          onCompleted: () => {
            onCompleted()
            void message.success(`復旧しました`)
          },
          onError: () => {
            void message.error(`エラーが発生しました`)
          },
        })
      }}
    >
      <Button type="primary" danger loading={loading}>
        復旧
      </Button>
    </Popconfirm>
  )
}

export const DeletedOrganizationsTable = ({
  organizations,
  onCompleted,
}: {
  organizations: OrganizationFieldsFragment[]
  onCompleted: () => void
}): ReactElement => (
  <Table
    rowKey="id"
    dataSource={organizations}
    pagination={{
      pageSize: 100,
    }}
    columns={[
      {
        title: '',
        dataIndex: 'imageUrl',
        render: (_, record): ReactElement => <Avatar src={record.imageUrl} shape="square" />,
      },
      {
        title: '組織名',
        dataIndex: 'name',
        sorter: (a, b) => compareFunction(a.name, b.name),
        ...getColumnSearchProperties((record: OrganizationFieldsFragment) => record.name),
      },
      {
        title: '',
        dataIndex: 'status',
        render: (_, record): ReactElement => <OrganizationStatusTag status={record.status} />,
      },
      {
        title: 'プラン',
        dataIndex: 'subscriptions',
        sorter: (a, b) =>
          compareFunction(a.subscriptions.map((x) => x.plan).join(','), b.subscriptions.map((x) => x.plan).join(',')),
        render: (_value, record): ReactElement => (
          <Space>
            {record.subscriptions.map((subscription, index) => (
              <SubscriptionPlanTag key={index} plan={subscription.plan} numberOfSeats={subscription.numberOfSeats} />
            ))}
          </Space>
        ),
      },
      {
        title: '作成日',
        dataIndex: 'createdAt',
        sorter: (a, b) => compareFunction(dayjs(a.createdAt).unix(), dayjs(b.createdAt).unix()),
      },
      {
        title: '削除日',
        dataIndex: 'deletedAt',
        sorter: (a, b) => compareFunction(dayjs(a.deletedAt).unix(), dayjs(b.deletedAt).unix()),
      },
      {
        title: '連携先',
        key: 'sources',
        filters: Object.values(SourceProviderEnum).map((x) => ({ value: x, text: x })),
        // フィルタ結果の処理
        onFilter: (value, record) =>
          pipe(
            record.sources,
            Array.map((x) => x.provider),
            Array.contains(value),
          ),
        sorter: (a, b) =>
          compareFunction(a.sources.map((x) => x.provider).join(','), b.sources.map((x) => x.provider).join(',')),
        render: (_, record): ReactElement => (
          <Space>
            {record.sources
              .filter((x) => x.provider !== SourceProviderEnum.Salescore)
              .map((source) => (
                <ProviderLogo
                  key={`${record.id}-${source.provider}`}
                  provider={source.provider}
                  active={source.connection?.active === true}
                />
              ))}
          </Space>
        ),
      },
      {
        title: '',
        key: 'restore',
        render: (_, record): ReactElement => (
          <RestoreDeletedOrganizationButton organizationId={record.id} onCompleted={onCompleted} />
        ),
      },
    ]}
  />
)
