import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  DeleteOrganizationDocument,
  type OrganizationFieldsFragment,
  OrganizationPlanTypeEnum,
  OrganizationStatusEnum,
  SourceProviderEnum,
  UpdateOrganizationDocument,
} from '@salescore/admin-client-api'
import { camelToPascal } from '@salescore/buff-common'
import { App, Avatar, Button, Popconfirm, Select, Space, Table, Tag } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import { Array, Order, pipe } from 'effect'
import { sumAll } from 'effect/Number'
import Link from 'next/link'
import type { ReactElement } from 'react'

import { getColumnSearchProps as getColumnSearchProperties } from '../../common/antd'
import { ORGANIZATION_STATUS_OPTIONS, OrganizationStatusTag } from '../shared/OrganizationStatusTag'
import { ProviderLogo } from '../shared/ProviderLogo'
import { SubscriptionPlanTag } from '../shared/SubscriptionPlanTag'
import { SubscriptionForms } from './SubscriptionForm'

const DeleteOrganizationButton = ({
  organizationId,
  onCompleted,
}: {
  organizationId: string
  onCompleted: () => void
}): ReactElement => {
  const { message } = App.useApp()
  const [deleteOrganizationMutation, { loading }] = useMutation(DeleteOrganizationDocument)

  return (
    <Popconfirm
      title="本当に削除しますか?"
      onConfirm={() => {
        void deleteOrganizationMutation({
          variables: {
            organizationId,
          },
          onCompleted: () => {
            onCompleted()
            void message.success(`削除しました`)
          },
          onError: (error) => {
            void message.error(`エラーが発生しました。` + error.message)
          },
        })
      }}
      okText="はい"
      cancelText="いいえ"
    >
      <Button type="text" loading={loading} icon={<DeleteOutlined />}></Button>
    </Popconfirm>
  )
}

const StatusSelect = ({
  organization,
  onCompleted,
}: {
  organization: OrganizationFieldsFragment
  onCompleted: () => void
}): ReactElement => {
  const { message } = App.useApp()
  const [updateOrganizationMutation, { loading }] = useMutation(UpdateOrganizationDocument)
  return (
    <Select
      variant={'borderless'}
      loading={loading}
      value={organization.status}
      onChange={(status) => {
        void updateOrganizationMutation({
          variables: {
            organizationId: organization.id,
            organization: {
              name: organization.name,
              adminMemo: organization.adminMemo,
              status,
            },
          },
          onCompleted: () => {
            onCompleted()
            void message.success(`更新しました`)
          },
          onError: (error) => {
            void message.error(`エラーが発生しました。` + error.message)
          },
        })
      }}
      options={ORGANIZATION_STATUS_OPTIONS.map((option) => ({
        value: option.value,
        label: <OrganizationStatusTag status={option.value} />,
      }))}
    />
  )
}

export const OrganizationsTable = ({
  organizations,
  onCompleted,
}: {
  organizations: OrganizationFieldsFragment[]
  onCompleted: () => void
}): ReactElement => {
  const { modal } = App.useApp()

  return (
    <Table
      rowKey="id"
      dataSource={organizations.map(
        (
          organization,
        ): OrganizationFieldsFragment & {
          createdAtDayjs: Dayjs
          subscriptionsKey: string
          sourcesKey: string
          seats: number
        } => ({
          ...organization,
          createdAtDayjs: dayjs(organization.createdAt),
          subscriptionsKey: organization.subscriptions.map((x) => x.plan).join(','),
          sourcesKey: organization.sources.map((x) => x.provider).join(','),
          seats: sumAll(organization.subscriptions.map((x) => x.numberOfSeats)),
        }),
      )}
      pagination={{
        pageSize: 100,
      }}
      columns={[
        {
          title: '',
          dataIndex: 'imageUrl',
          render: (_, record) => <Avatar src={record.imageUrl} shape="square" />,
        },
        {
          title: '組織名',
          dataIndex: 'name',
          sorter: (a, b) => Order.string(a.name, b.name),
          ...getColumnSearchProperties((record: OrganizationFieldsFragment) => record.name),
        },
        {
          title: '',
          dataIndex: 'status',
          sorter: (a, b) => Order.string(a.status, b.status),
          filters: ORGANIZATION_STATUS_OPTIONS.map((x) => ({ value: x.value, text: x.label })),
          onFilter: (value, record) => record.status === value,
          render: (_, record) => <StatusSelect organization={record} onCompleted={onCompleted} />,
        },
        {
          title: 'プラン',
          dataIndex: 'subscriptions',
          sorter: (a, b) => Order.string(a.subscriptionsKey, b.subscriptionsKey),
          render: (_value, record) => (
            <div
              onClick={() => {
                const m = modal.info({
                  width: 'min(80%, 1200px)',
                  okText: '閉じる',
                  okType: 'default',
                  maskClosable: true,
                  title: 'ライセンス編集',
                  content: (
                    <SubscriptionForms
                      organization={record}
                      subscriptions={record.subscriptions}
                      onCompleted={() => {
                        onCompleted()
                        m.destroy()
                      }}
                    />
                  ),
                })
              }}
              className="cursor-pointer"
            >
              <Space>
                {record.subscriptions.map((subscription, index) => (
                  <SubscriptionPlanTag
                    key={index}
                    plan={subscription.plan}
                    numberOfSeats={subscription.numberOfSeats}
                  />
                ))}
                {record.organizationPlans.map((x, index) => (
                  <Tag color="yellow" key={index}>
                    {camelToPascal(x.license)} {x.plan === OrganizationPlanTypeEnum.Free ? '(free)' : ''}
                  </Tag>
                ))}
                {Array.isEmptyArray(record.subscriptions) && Array.isEmptyArray(record.organizationPlans) && (
                  <span>なし</span>
                )}
                <EditOutlined />
              </Space>
            </div>
          ),
        },
        {
          title: '作成日',
          dataIndex: 'createdAt',
          sorter: (a, b) => Order.number(a.createdAtDayjs.unix(), b.createdAtDayjs.unix()),
        },
        {
          title: '合計ライセンス数',
          key: 'subscription',
          sorter: (a, b) => Order.number(a.seats, b.seats),
          render: (_, record) => <Space>{record.seats}</Space>,
        },
        {
          title: '連携先',
          key: 'sources',
          filters: Object.values(SourceProviderEnum).map((x) => ({ value: x, text: x })),
          // フィルタ結果の処理
          onFilter: (value, record) =>
            pipe(
              record.sources,
              Array.map((x) => x.provider),
              Array.contains(value),
            ),
          sorter: (a, b) => Order.string(a.sourcesKey, b.sourcesKey),
          render: (_, record) => (
            <Space>
              {record.sources
                .filter((x) => x.provider !== SourceProviderEnum.Salescore)
                .map((source) => (
                  <ProviderLogo
                    key={`${record.id}-${source.id}`}
                    provider={source.provider}
                    active={source.connection?.active === true}
                  />
                ))}
            </Space>
          ),
        },
        {
          title: '',
          key: 'externalLink',
          render: (_, record) => (
            <a
              href={`${process.env.FRONT_MAIN_URL ?? 'https://app.salescore.jp'}/o/${record.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button icon={<LinkOutlined />}>本番</Button>
            </a>
          ),
        },
        {
          title: '',
          key: 'link',
          render: (_, record) => (
            <Link href={`/o/${record.id}`} legacyBehavior>
              <Button>詳細</Button>
            </Link>
          ),
        },
        {
          title: '',
          key: 'destroy',
          width: 20,
          render: (_text, record): ReactElement => {
            if (record.status === OrganizationStatusEnum.Suspended) {
              return <></>
            }

            return <DeleteOrganizationButton onCompleted={onCompleted} organizationId={record.id} />
          },
        },
      ]}
    />
  )
}
