import { PlusOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import type { OrganizationFieldsFragment } from '@salescore/admin-client-api'
import {
  FetchAdminOrganizationsDocument,
  SubscriptionPlanEnum,
  UserLicenseTypeEnum,
  UserPlanEnum,
} from '@salescore/admin-client-api'
import { App, Button, Card, Tabs } from 'antd'
import { type ReactElement, useTransition } from 'react'

import { HandleQuery } from '../presentation/common/HandleQuery'
import { CreateOrganizationForm } from '../presentation/components/organizations/CreateOrganizationForm'
import { DeletedOrganizationsTable } from '../presentation/components/organizations/DeletedOrganizationsTable'
import { OrganizationsTable } from '../presentation/components/organizations/OrganizationsTable'
import { CsvDownloadButton } from '../presentation/components/shared/CsvDownloadButton'
import { SUBSCRIPTION_STATUS_JA } from '../presentation/components/shared/SubscriptionPlanTag'

const { TabPane } = Tabs

const getFreeLicenseCount = (org: OrganizationFieldsFragment, license: UserLicenseTypeEnum): number | '' => {
  const count = org.users.filter(
    (user) =>
      user.role !== 'admin' &&
      user.userLicenses.some(
        (userLicense) => userLicense.license === license && userLicense.plan === UserPlanEnum.Free,
      ),
  ).length
  // 招待中のユーザーを取得
  const invitationCount = org.invitations.filter(
    (invitation) =>
      invitation.role !== 'admin' &&
      ((license === UserLicenseTypeEnum.Sync && invitation.planForSync === UserPlanEnum.Free) ||
        (license === UserLicenseTypeEnum.Visualize && invitation.planForVis === UserPlanEnum.Free)),
  ).length
  const totalCount = count + invitationCount
  return totalCount > 0 ? totalCount : ''
}

const Page = (): ReactElement => {
  const { modal } = App.useApp()
  const {
    data: { adminOrganizations },
    refetch,
  } = useSuspenseQuery(FetchAdminOrganizationsDocument, {
    variables: {
      shouldFetchDeleted: false,
    },
    fetchPolicy: 'cache-and-network',
  })
  const {
    data: { adminOrganizations: deletedOrganizations },
    refetch: refetchDeleted,
  } = useSuspenseQuery(FetchAdminOrganizationsDocument, {
    variables: {
      shouldFetchDeleted: true,
    },
    fetchPolicy: 'cache-and-network',
  })
  const [, startTransition] = useTransition()
  const refetchOrganizations = () => {
    startTransition(() => {
      void refetch()
      void refetchDeleted()
    })
  }

  const csvData = {
    header: [
      '組織名',
      SUBSCRIPTION_STATUS_JA.visualize,
      SUBSCRIPTION_STATUS_JA.visualize + '(無料)',
      SUBSCRIPTION_STATUS_JA.sync,
      SUBSCRIPTION_STATUS_JA.sync + '(無料)',
      SUBSCRIPTION_STATUS_JA.ri,
      SUBSCRIPTION_STATUS_JA.ri + '(無料)',
      'DataExtension',
    ],
    body: adminOrganizations.map((org) => [
      // 組織名
      org.name,
      // VISUALIZE
      org.subscriptions.find((subscription) => subscription.plan === SubscriptionPlanEnum.Visualize)?.numberOfSeats ??
        '',
      // VISUALIZE(無料)
      getFreeLicenseCount(org, UserLicenseTypeEnum.Visualize),
      // SYNC
      org.subscriptions.find((subscription) => subscription.plan === SubscriptionPlanEnum.Sync)?.numberOfSeats ?? '',
      // SYNC(無料)
      getFreeLicenseCount(org, UserLicenseTypeEnum.Sync),
      // RI
      org.subscriptions.find((subscription) => subscription.plan === SubscriptionPlanEnum.Ri)?.numberOfSeats ?? '',
      // RI(無料)
      getFreeLicenseCount(org, UserLicenseTypeEnum.Ri),
      // DataExtension
      org.organizationPlans.find((plan) => plan.license === 'dataExtension')?.plan ?? '',
    ]),
  }

  return (
    <Card
      className="m-5"
      title="組織一覧"
      extra={[
        <CsvDownloadButton key="button-download" filename="組織一覧" headers={csvData.header} data={csvData.body} />,
        <Button
          className="ml-2"
          key="button-create"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            const m = modal.info({
              width: 'min(80%, 1200px)',
              okText: '閉じる',
              okType: 'default',
              title: '新規組織作成',
              content: (
                <CreateOrganizationForm
                  onAfterFinish={() => {
                    startTransition(() => {
                      void refetch()
                      m.destroy()
                    })
                  }}
                />
              ),
            })
          }}
        >
          新規作成
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="一覧" key="1">
          <HandleQuery>
            <OrganizationsTable
              organizations={adminOrganizations}
              onCompleted={() => {
                startTransition(() => {
                  void refetch()
                  void refetchDeleted()
                })
              }}
            />
          </HandleQuery>
        </TabPane>
        <TabPane tab="削除済み一覧" key="2">
          <HandleQuery>
            <DeletedOrganizationsTable organizations={deletedOrganizations} onCompleted={refetchOrganizations} />
          </HandleQuery>
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default Page
