import { SubscriptionPlanEnum } from '@salescore/admin-client-api'
import { Tag } from 'antd'

export const SUBSCRIPTION_STATUS_OPTIONS = [
  {
    label: 'VISUALIZE',
    value: SubscriptionPlanEnum.Visualize,
  },
  {
    label: 'SYNC',
    value: SubscriptionPlanEnum.Sync,
  },
  {
    label: 'RI',
    value: SubscriptionPlanEnum.Ri,
  },
]

const SUBSCRIPTION_STATUS_COLORS = {
  [SubscriptionPlanEnum.Visualize]: 'green',
  [SubscriptionPlanEnum.Sync]: 'blue',
  [SubscriptionPlanEnum.Ri]: 'volcano',
}

export const SUBSCRIPTION_STATUS_JA = {
  [SubscriptionPlanEnum.Visualize]: 'VISUALIZE',
  [SubscriptionPlanEnum.Sync]: 'SYNC',
  [SubscriptionPlanEnum.Ri]: 'RI',
}

export const SubscriptionPlanTag = ({ plan, numberOfSeats }: { plan: SubscriptionPlanEnum; numberOfSeats: number }) => {
  switch (plan) {
    case 'visualize':
    case 'sync':
    case 'ri': {
      return (
        <Tag color={SUBSCRIPTION_STATUS_COLORS[plan]}>
          {SUBSCRIPTION_STATUS_JA[plan]}({numberOfSeats})
        </Tag>
      )
    }
    default: {
      return <></>
    }
  }
}
