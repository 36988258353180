import { SaveOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  CreateOrganizationDocument,
  OrganizationPlanTypeEnum,
  OrganizationStatusEnum,
} from '@salescore/admin-client-api'
import { Button, Form, Input, message, Select } from 'antd'
import { t } from 'i18next'
import type { ReactElement } from 'react'

import { ORGANIZATION_PLAN_OPTIONS } from '../../../common/planOptions'
import { ORGANIZATION_STATUS_OPTIONS, OrganizationStatusTag } from '../shared/OrganizationStatusTag'

interface FormValue {
  name: string
  status: OrganizationStatusEnum
  adminMemo: string | undefined
  dataExtensionPlan: OrganizationPlanTypeEnum
}

export const CreateOrganizationForm = ({ onAfterFinish }: { onAfterFinish: () => void }): ReactElement => {
  const [createOrganizationMutation, { loading }] = useMutation(CreateOrganizationDocument)

  const onFinish = (value: FormValue): void => {
    void createOrganizationMutation({
      variables: {
        organization: {
          name: value.name,
          status: value.status,
          adminMemo: value.adminMemo ?? '',
          ...(value.dataExtensionPlan === OrganizationPlanTypeEnum.Free ||
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          value.dataExtensionPlan === OrganizationPlanTypeEnum.Standard
            ? { dataExtensionPlan: value.dataExtensionPlan }
            : {}),
        },
      },
      onCompleted: () => {
        void message.success(t(`更新しました`))
        onAfterFinish()
      },
    })
  }

  return (
    <Form
      onFinish={onFinish}
      initialValues={{ status: OrganizationStatusEnum.Using, dataExtensionPlan: '' }}
      style={{ width: 800 }}
    >
      <Form.Item label="組織名" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="ステータス" name="status" rules={[{ required: true }]}>
        <Select
          variant="borderless"
          style={{ width: 110 }}
          options={ORGANIZATION_STATUS_OPTIONS.map((option) => ({
            value: option.value,
            label: <OrganizationStatusTag status={option.value} />,
          }))}
        />
      </Form.Item>
      <Form.Item label="内部メモ(任意)" name="adminMemo">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="組織ライセンス(DataExtension)" name="dataExtensionPlan">
        <Select options={ORGANIZATION_PLAN_OPTIONS} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
          保存
        </Button>
      </Form.Item>
    </Form>
  )
}
